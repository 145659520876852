import { Sidenav } from "mdb-ui-kit";
export const initializeSidenav = sidenav => {
  const sidenavInstance = new Sidenav(sidenav);
  let innerWidth = null;
  const setMode = e => {
    // Check necessary for Android devices
    if (window.innerWidth === innerWidth) {
      return;
    }
    innerWidth = window.innerWidth;
    if (window.innerWidth < 768) {
      sidenavInstance.changeMode('over');
      sidenavInstance.hide();
    } else {
      sidenavInstance.changeMode('side');
      sidenavInstance.show();
    }
  };
  setMode();

  // Event listeners
  window.addEventListener('resize', setMode);
};